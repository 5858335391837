import React from 'react';
import { Accordion, AccordionPanel, Box } from 'grommet';
import { graphql, useStaticQuery } from 'gatsby';
import Markdown from '../markdown/markdown';

const renderQuestions = list => {
  return list.map((item, index) => (
    <AccordionPanel
      key={index}
      label={item.title}
      multiple={false}
      pad={{ vertical: 'medium' }}
    >
      <Box pad="medium">
        <Markdown>{item.content.childMarkdownRemark.rawMarkdownBody}</Markdown>
      </Box>
    </AccordionPanel>
  ));
};

const Faq = () => {
  const data = useStaticQuery(graphql`
    query FaqQuery {
      faqJson {
        questions {
          title
          content {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
  `);

  return <Accordion>{renderQuestions(data.faqJson.questions)}</Accordion>;
};

export default Faq;
