import React from 'react';
import PropTypes from 'prop-types';
import { Text, Box } from 'grommet';
import SectionContainer from '../sections/SectionContainer';
import CustomLi from '../markdown/customLi';

const PlanFeatures = ({ features, width }) => {
  return (
    <SectionContainer pad={{ bottom: 'xlarge' }}>
      <Box
        id="plan-details"
        pad="medium"
        align="center"
        background={{ color: 'neutral-2', opacity: '.8' }}
        width={width}
      >
        <Text size="xlarge" color="brand" textAlign="center">
          What you get
        </Text>
        <ul>
          {features.map((feature, index) => (
            <CustomLi key={index}>{feature}</CustomLi>
          ))}
        </ul>
      </Box>
    </SectionContainer>
  );
};

export default PlanFeatures;

PlanFeatures.propTypes = {
  width: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
};
