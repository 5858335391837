import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Stack, Box, ResponsiveContext, Heading } from 'grommet';
import SectionContainer from '../components/sections/SectionContainer';
import PreFooter from '../components/prefooter/PreFooter';
import PriceBox from '../components/pricing/priceBox';
import PlanFeatures from '../components/pricing/planFeatures';
import Faq from '../components/faq';

const Pricing = ({ data }) => {
  return (
    <Layout pageTitle="Pricing">
      <ResponsiveContext.Consumer>
        {size => {
          const priceBoxWidth = size === 'small' ? '95vw' : 'medium';

          return (
            <>
              <SectionContainer>
                <Stack anchor="bottom" fill>
                  <Box height="450px">
                    <Img
                      style={{ width: '100%', height: '100%' }}
                      imgStyle={{ objectPosition: 'top center' }}
                      fluid={data.pricingJson.heroImage.childImageSharp.fluid}
                      alt={data.pricingJson.heroImage.title}
                      loading="eager"
                    />
                  </Box>
                  <PriceBox
                    width={priceBoxWidth}
                    title={data.pricingJson.title}
                    price={data.pricingJson.price}
                  />
                </Stack>
              </SectionContainer>
              <PlanFeatures
                width={priceBoxWidth}
                features={data.pricingJson.planFeatures}
              />
            </>
          );
        }}
      </ResponsiveContext.Consumer>
      <Box fill align="center">
        <Heading level={2} textAlign="center">
          FAQ
        </Heading>
      </Box>
      <Box align="center" fill>
        <Box pad="large" width="large">
          <Faq />
        </Box>
      </Box>
      <PreFooter />
    </Layout>
  );
};

export default Pricing;

Pricing.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query PrcingPageQuery {
    pricingJson {
      title
      heroImage {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      price
      planName
      planFeatures
    }
  }
`;
